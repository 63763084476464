<template lang="pug">
  active-item(:is-active="$mq === 'mobile'")
    .dropdown.relative.inline-block.w-full.mr-6(:class="{active: active}" slot-scope="{ setActive, active }"  v-click-outside="() => { if ($mq === 'desktop') return setActive(false) }")
      .dropdown__btn.w-full.border-2.border-gray-400.relative.text-base.text-gray.font-normal.py-select.pl-8.pr-16.text-left.inline-block.rounded.leading-10.overflow-hidden(class="hidden xl:block" @click.prevent="if ($mq === 'desktop') setActive(!active); return")
        .line-clamp-1 {{ label }}

      collapse-transition
        .dropdown__menu.w-full.rounded.top-dropdown.bg-white.min-w-full.left-0.z-40.pb-10.px-12(class="xl:min-w-dropdown-surface xl:py-8 xl:shadow xl:w-auto xl:absolute" v-show="active")
          p.title-400.mb-6 Surface
          .grid.grid-cols-2.gap-4
            .form-group
              .form-icon-wrapper
                input-cleave.form-control(placeholder="Min" type="text" v-model="minSurface")
                span.form-control__icon.text-base m²
            .form-group
              .form-icon-wrapper
                input-cleave.form-control(placeholder="Max" type="text" v-model="maxSurface")
                span.form-control__icon.text-base m²
</template>

<script>
import { debounce } from 'lodash'
import InputCleave from '@components/InputCleave'

export default {
  components: { InputCleave },

  props: {
    searchParams: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      minSurface: this.searchParams.by_min_surface,
      maxSurface: this.searchParams.by_max_surface,
      label: 'Surface'
    }
  },

  computed: {
    mutableProperties () {
      return this.minSurface, this.maxSurface, Date.now() // eslint-disable-line
    }
  },

  watch: {
    mutableProperties: debounce(function () {
      if (parseInt(this.minSurface) === 0 || this.minSurface === '') {
        this.$store.commit('deleteParams', 'by_min_surface')
      } else {
        this.$store.commit('updateParams', { key: 'by_min_surface', value: this.minSurface })
      }

      if (parseInt(this.maxSurface) === 0 || this.maxSurface === '') {
        this.$store.commit('deleteParams', 'by_max_surface')
      } else {
        this.$store.commit('updateParams', { key: 'by_max_surface', value: this.maxSurface })
      }

      this.$store.dispatch('searchAccommodations')
        .then(({ surfaceLabel }) => {
          this.label = surfaceLabel
        })
        .catch((e) => {
          console.debug(e.message)
        })
    }, 300)
  },

  mounted () {
    this.$nextTick(() => {
      this.$store.commit('updateReady', 'surfaceRange')
    })
  }
}
</script>

<template lang="pug">
div(class="mb-10 xl:mb-12")
  p.title-400.mb-6 N° de référence
  div
    input(placeholder="Référence" class="form-control" type="text" v-model="reference")
</template>

<script>
import { debounce } from 'lodash'

export default {
  props: {
    initialReference: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      reference: this.initialReference
    }
  },

  watch: {
    reference: debounce(function () {
      if (this.reference === '') {
        this.$store.commit('deleteParams', 'by_reference')
      } else {
        this.$store.commit('updateParams', { key: 'by_reference', value: this.reference })
      }

      this.$store.dispatch('searchAccommodations')
        .then(({ facilitiesLabel }) => {
          this.$emit('change', facilitiesLabel)
        })
        .catch((e) => {
          console.debug(e.message)
        })
    }, 300)
  },

  mounted () {
    this.$store.commit('updateReady', 'reference')
  }
}
</script>

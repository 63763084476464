<template lang="pug">
div(class="mb-10 xl:mb-12")
  p.title-400.mb-6 Chauffage
  div(class="xl:grid xl:grid-cols-2 xl:gap-4")
    div
      label(class="checkbox-native block font-sans relative pl-10 mb-6 text-base font-light text-gray-800 cursor-pointer select-none" v-for="heatingType in accommodationHeatingTypes" :key="heatingType.value")
        span {{ heatingType.label }}
        input(class="checkbox-native__input absolute opacity-0 cursor-pointer h-0 w-0" type="checkbox" name="heating_type" :value="heatingType.value" v-model="selectedHeatingTypes")
        span(class="checkbox-native__checkmark absolute top-1/2 transform -translate-y-1/2 left-0 w-6 h-6 border border-black rounded-sm")

    div
      label(class="checkbox-native block font-sans relative pl-10 mb-6 text-base font-light text-gray-800 cursor-pointer select-none" v-for="heatingMode in accommodationHeatingModes" :key="heatingMode.value")
        span {{ heatingMode.label }}
        input(class="checkbox-native__input absolute opacity-0 cursor-pointer h-0 w-0" type="checkbox" name="heating_type" :value="heatingMode.value" v-model="selectedHeatingModes")
        span(class="checkbox-native__checkmark absolute top-1/2 transform -translate-y-1/2 left-0 w-6 h-6 border border-black rounded-sm")
</template>

<script>
export default {
  props: {
    searchParams: {
      type: Object,
      required: true
    },

    accommodationHeatingTypes: {
      type: Array,
      required: true
    },

    accommodationHeatingModes: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      selectedHeatingTypes: [],
      selectedHeatingModes: []
    }
  },

  watch: {
    selectedHeatingTypes () {
      this.$store.commit('updateParams', { key: 'by_heating_types', value: this.selectedHeatingTypes })

      this.$store.dispatch('searchAccommodations')
        .then(({ facilitiesLabel }) => {
          this.$emit('change', facilitiesLabel)
        })
        .catch((e) => {
          console.debug(e.message)
        })
    },

    selectedHeatingModes () {
      this.$store.commit('updateParams', { key: 'by_heating_modes', value: this.selectedHeatingModes })

      this.$store.dispatch('searchAccommodations')
        .then(({ facilitiesLabel }) => {
          this.$emit('change', facilitiesLabel)
        })
        .catch((e) => {
          console.debug(e.message)
        })
    }
  },

  mounted () {
    this.selectedHeatingTypes = Array.isArray(this.searchParams.by_heating_types) ? [...this.searchParams.by_heating_types] : []
    this.selectedHeatingModes = Array.isArray(this.searchParams.by_heating_modes) ? [...this.searchParams.by_heating_modes] : []

    this.$nextTick(() => {
      this.$store.commit('updateReady', 'heatingTypes')
    })
  }
}
</script>

import axios from 'axios'

const state = {
  endpoint: '/accommodations/search',
  params: {},
  accommodationsList: null,
  switchButton: null,
  accommodations: [],
  alertTags: null,
  childrenReady: {
    contractType: false,
    locationsSelect: false,
    roomCheckboxes: false,
    accommodationTypesCheckboxes: false,
    budgetRange: false,
    surfaceRange: false,
    reference: false,
    facilities: false,
    heatingTypes: false,
    selectOrder: false
  }
}

const mutations = {
  updateParams (state, payload) {
    if (payload.value === '' || payload.value === null) return false

    state.params = {
      ...state.params,
      [payload.key]: payload.value
    }
  },

  updateReady (state, payload) {
    state.childrenReady = {
      ...state.childrenReady,
      [payload]: true
    }
  },

  setAccommodationsList (state, payload) {
    state.accommodationsList = payload
  },

  setSwitchButton (state, payload) {
    state.switchButton = payload
  },

  setAccommodations (state, payload) {
    state.accommodations = payload
  },

  setAlertTags (state, payload) {
    state.alertTags = payload
  },

  deleteParams (state, payload) {
    delete state.params[payload]
  }
}

const getters = {
  getAccommodationsList (state) {
    return state.accommodationsList
  },

  getSwitchButton (state) {
    return state.switchButton
  },

  getAccommodations (state) {
    return state.accommodations
  },

  getParams (state) {
    return state.params
  },

  getAlertTags (state) {
    return state.alertTags
  }
}

const actions = {
  async searchAccommodations ({ commit }) {
    if (Object.values(state.childrenReady).includes(false)) {
      return Promise.reject(new Error('Not ready'))
    }

    return axios.get(state.endpoint, {
      params: state.params
    })
      .then(({ data }) => {
        history.pushState(null, null, data.location)

        commit('setAccommodations', data.accommodations)
        commit('setAccommodationsList', data.content)
        commit('setSwitchButton', data.switchButton)
        commit('setAlertTags', data.alertTags)

        return data
      })
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}

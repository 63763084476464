<template lang="pug">
  active-item(:is-active="$mq === 'mobile'")
    .dropdown.relative.inline-block.w-full.mr-6(:class="{active: active}" slot-scope="{ setActive, active }"  v-click-outside="() => { if ($mq === 'desktop') return setActive(false) }")
      .dropdown__btn.w-full.border-2.border-gray-400.relative.text-base.text-gray.font-normal.py-select.pl-8.pr-16.text-left.inline-block.rounded.leading-10.overflow-hidden(class="hidden xl:block" @click.prevent="if ($mq === 'desktop') setActive(!active); return")
        .line-clamp-1 {{ label }}

      collapse-transition
        .dropdown__menu.w-full.rounded.top-dropdown.bg-white.left-0.z-40.pb-10.px-12(class="xl:min-w-dropdown-bedroom xl:py-8 xl:shadow xl:w-auto xl:absolute" v-show="active")
          p.title-400.mb-6 Nombre de pièces ?
          .flex.flex-wrap
            label(class="radio-button inline-block mr-2 mb-4 relative text-base cursor-pointer select-none lg:mr-4" v-for="value in values" :key="value")
              input(class="radio-button__input absolute opacity-0 cursor-pointer h-0 w-0" type="checkbox" name="contract_type" :value="value" v-model="selectedValues")
              p(class="radio-button__checkmark btn-outline p-0 flex items-center justify-center w-radio-button h-radio-button transition duration-500 ease-in-out")
                span {{ value }}
</template>

<script>
export default {
  props: {
    initialRooms: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data () {
    return {
      selectedValues: [],
      values: ["1", "2", "3", "4", "5+"],
      label: 'Nb de pièces'
    }
  },

  watch: {
    selectedValues () {
      this.$store.commit('updateParams', { key: 'by_rooms', value: this.selectedValues })

      this.$store.dispatch('searchAccommodations')
        .then(({ roomsLabel }) => {
          this.label = roomsLabel
        })
        .catch((e) => {
          console.debug(e.message)
        })
    }
  },

  mounted () {
    this.selectedValues = [...this.initialRooms]

    this.$nextTick(() => {
      this.$store.commit('updateReady', 'roomCheckboxes')
    })
  }
}
</script>
